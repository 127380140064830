import React, {useEffect, useState} from "react";
import {getHttp, postHttp} from "../../api/HttpClient";
import Select from "react-dropdown-select";
import {validateHelper} from "../../helpers/validateHelper";
import {AddressRules} from "../../rules/AddressRules";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";
import {GoogleModal} from "../../compoments/GoogleModal";
import {ShowError} from "../../compoments/showError";
import {initialStateResult} from "./AddressPage";
import {buildUrlBase} from "../../utils/BuildUrlBase";
interface Props {
  onPress: Function;
}
export const ContentRegister = ({onPress}: Props) => {
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState({
    department_id: '',
    street: '',
    number: '',
    latitude: '',
    longitude: '',
    reference_detail: '',
  });
  const [errorRegister, setErrorRegister] = useState({
    department_id: '',
    street: '',
    number: '',
    latitude: '',
    longitude: '',
    reference_detail: '',
  });
  useEffect(() => {
    (async () => await loadData())();
  }, []);
const loadData = async () => {
  const {response, success} = await getHttp('statesByCountry');
  if (success) {
    const departs = response.map( (item: any) => {
      return   { value: item.id, label: item.name };
    })
    setDepartments(departs);

  }

}
const optionChange = (selected: any, field: string) => {
  setData({...data, [field]: selected.value});
  onValidate(selected.value, field);
}
const onChange = (event:any, field: string) => {
  setData({...data, [field]: event.target.value});
  onValidate(event.target.value, field);
}

  const onValidate = (value: any, field: string) => {
    const inputRules = getRulesByField(field);
    const resultValidate = validateHelper(field, value, inputRules, data);
    // @ts-ignore
    errorRegister[field] = resultValidate;
    // setErrorRegister({...errorRegister, [field]: resultValidate});
    setErrorRegister({...errorRegister});
  };
  const getRulesByField = (field: string) => {
    for (const [key, value] of Object.entries(AddressRules)) {
      if (key === field) {
        return value;
      }
    }
    return [];
  }
  const checkValidate = () => {
    for (const [key, value] of Object.entries(data)) {
      onValidate(value, key);
    }
  }
  const isValid = () => {
    let count = 0;
    for (let [key, value] of Object.entries(errorRegister)) {
      if (value) {
        count = count + 1;
      }
    }
    return count;
  };
  const onSubmit = async () => {
    checkValidate();
    setLoading(true);
    if (isValid() == 0) {
      const body = {
        department_id: data.department_id,
        street: data.street,
        number: data.number,
        latitude: data.latitude,
        longitude: data.longitude,
        reference_detail: data.reference_detail,
      }
      const {response, success, message, errors} = await postHttp(buildUrlBase('address'), body);
      if (success) {
        setLoading(false);
        onPress({...initialStateResult, action: 'list', status: 'success', message})
      } else {
        setLoading(false);
      }
    } else {
      alert('Error revise el formulario');
      setLoading(false);
    }
  }
  const onMap = () => {
    setVisible(true);
  }
  const onConfirm = (latlng: any) => {
    if (latlng?.lat != '') {
      data.latitude = latlng.lat;
      data.longitude = latlng.lng;
      setData({...data});
      setVisible(false);
    }
    setVisible(false);
  }
  const onCloseModal = () => {
    setVisible(false);
  }
  return <div className="page-content-wrapper">
        <div className="row mb-3">
          <div className="col-12 d-sm-flex justify-content-between align-items-center">
            <h1 className="h3 mb-2 mb-sm-0">Mis direcciones </h1>
            <button className="btn btn-sm btn-primary mb-0" onClick={() => onPress({...initialStateResult, action: 'list'})}>List</button>
          </div>
        </div>
    <div className="card bg-transparent border">
      <div className="card-header bg-light border-bottom">
        <div className="row g-3 align-items-center justify-content-between">

        </div>
      </div>
      <div className="card-body">
        <form className="row g-3 mt-0">
          <div className="col-md-12 bg-light-input">
            <label htmlFor="mobileNumber" className="form-label">Seleccione el departamento *</label>
            <Select required
                    className={'form-control'}
                    options={departments}
                    onChange={(value: any) => optionChange(value[0],'department_id')} values={[]}/>
            <ShowError message={errorRegister.department_id} />
          </div>
          <div className="col-md-12 bg-light-input">
            <label htmlFor="yourName" className="form-label">Dirección *</label>
            <input type="text"
                   className="form-control"
                   id="street"
                   onChange={event => onChange(event, 'street')}
                   placeholder="Name" />
            <ShowError message={errorRegister.street} />
          </div>
          <div className={'row'}>
            <div className="col-md-6 bg-light-input">
              <label htmlFor="yourName" className="form-label">Google Map *</label>
              <FontAwesomeIcon icon={faLocationDot} size={'3x'}  onClick={() => onMap()}/>
              <ShowError message={errorRegister.latitude} />
            </div>
          </div>
          <div className="col-md-12 bg-light-input">
            <label htmlFor="emailInput" className="form-label">Nro. de casa *</label>
            <input type="text"
                   className="form-control"
                   id="number"
                   onChange={event => onChange(event, 'number')}
                   placeholder="Nro. " />
            <ShowError message={errorRegister.number} />
          </div>

          <div className="col-md-12 bg-light-input">
            <label htmlFor="postalCode" className="form-label">Mas referencias *</label>
            <input type="text"
                   className="form-control"
                   id="reference_detail"
                   onChange={event => onChange(event, 'reference_detail')}
                   placeholder=" Mas referencias "/>
            <ShowError message={errorRegister.reference_detail} />
          </div>
          <div className="col-12 text-end">
            <button type="button" className="btn btn-success" onClick={() => onSubmit() } >Guardar</button>
          </div>
        </form>
      </div>
    </div>
    {visible && <GoogleModal
        visible={visible}
        map={data}
        onSave={(value: any) => onConfirm(value)}
        onClose={() => onCloseModal()}/>}
  </div>
}
