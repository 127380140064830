import {useAppDispatch} from "../hooks/hook";
import {useEffect} from "react";
import {getCurrentUser, getParentSession} from "../services/LocalService";
import {setCheckToken, setLogin, setLoginError} from "../store/slices/authSlice";
import {useSearchParams} from "react-router-dom";
import {postHttp} from "../api/HttpClient";
import {buildUrlBase} from "../utils/BuildUrlBase";
import {buildUrlClient} from "../utils/BuildUrlClient";
interface Props {
    children: JSX.Element | JSX.Element[]
}
 export const AppState = ({ children }: Props) => {
     const [queryParameters] = useSearchParams()
     const auth = queryParameters.get("auth")
     console.log(' authtes ', auth);
     const dispatch = useAppDispatch();
     useEffect(() => {
         (async () => await checkToken())();
     }, []);
     const onSession = async () => {
         if (auth != '' && auth != undefined) {
             const body ={
                  auth
             }
             const  {response, success, message} =  await postHttp(buildUrlClient('onLogin'), body)
             if (success) {
                 const {id,name,auth_token, last_name, is_support} = response;
                 dispatch(setLogin({status: 'authenticated', message: message, session: {id, name, last_name, token: auth_token, is_support} }))
             } else {
                 dispatch(setLoginError({status: 'notAuthenticated', message: message, session: null }))
             }

         }
     }
     const checkToken = async () => {
         const currentSession = await getCurrentUser();
         const parentId = await getParentSession();
         console.log(' parentId' , parentId);
         if (currentSession != '') {
             dispatch(setCheckToken({status: 'authenticated',  message: '', session: currentSession, parentId: parentId }));
         } else {
            await onSession();
         }
     }
   return <> {children} </>
 }
