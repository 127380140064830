import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../hooks/hook";
import {RootState} from "../store/store";
import {STEPPERS} from "../utils/Constants";
import {getHourByDay, getServicesCalendar} from "../store/thunks/appointmentThunk";
import {Counter} from "../models/Counter";
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import {getServiceSelected} from "../core/calendar/getServiceActive";
import {getQuantityHourByService} from "../core/calendar/getQuantityHourByService";
import {getFormatDate, getFormatDateF} from "../core/getFormatDate";
import {addSchedule} from "../core/calendar/addSchedule";
import {assignedHour} from "../core/calendar/assignedHour";
import {setAssignedSchedule, setHoursByDay, setServicesSchedule} from "../store/slices/appointmentSlice";
import {HourDayModel} from "../models/HourDayModel";
import {HourModel} from "../models/HourModel";
import {Button, Modal} from "react-bootstrap";
import {getHttp} from "../api/HttpClient";
import {buildUrlBase} from "../utils/BuildUrlBase";
import {buildUrlClient} from "../utils/BuildUrlClient";
import moment from "moment";
import {getCurrentDate, getCurrentDateFormat} from "../core/calendar/getCurrentDate";
import {checkServicesAssigned} from "../core/calendar/checkServicesAssigned";
// import "react-modern-calendar-datepicker/lib/DatePicker.css";
// import { Calendar } from "react-modern-calendar-datepicker";
interface DataModal {
    date?: string;
    service?: any,
    hours?: any
}
export const CalendarView = () => {
    const dispatch = useAppDispatch()
    const {stepper, provider, payment, hours} = useAppSelector((state: RootState) => state.appointment);
    const {services} = payment;
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [currentDate, setCurrentDate] = useState(getCurrentDateFormat());
    const [show, setShow] = useState(false);
    const [professionals, setProfessionals] = useState([]);
    const [data, setData] = useState<DataModal>({});
    useEffect(() => {
    (async () => await loadData())();
    }, [stepper]);

    const loadData = async () => {
        const {counters} = payment;
        if (stepper === STEPPERS.CALENDAR) {
            console.log(' counters ', counters);
            const servicesIds = counters?.map((item: Counter) => item.serviceId).toString();
            console.log(' servicesIds ', servicesIds);
            const params = {
                service_id: servicesIds,
            };
            const selectedDateFormat = await getFormatDate(selectedDate);
          dispatch(getServicesCalendar(params, counters, provider, selectedDateFormat));
        }
    }
    const dateDisable =  (day: any) => {
            if ( getFormatDateF(day.date) >= currentDate) {
                return false
            }
            return true;
    }

const onSelectedDay =  async (date: any) => {
    const {services} = payment;
    setSelectedDate(date);
    const selectedDateFormat = await getFormatDate(date);
    const serviceSelected =  await getServiceSelected(services) as any
    if (serviceSelected) {
        const {id,independent} = provider as any
        const params = {
            id,
            date: selectedDateFormat,
            independent,
            type: serviceSelected.typeId,
            min_time: serviceSelected?.minTime,
        };
        dispatch(getHourByDay(params, selectedDateFormat, services));
    }
}
const onClickHour = async (hour: any) => {
    // provider
    const {id,independent} = provider as any
    const serviceSelected =  await getServiceSelected(services);

    const selectedDateFormat = await getFormatDate(selectedDate);
    if (hour.assigned == 0 && serviceSelected.professionalId == 0 ) {
        const quantityHourService = await getQuantityHourByService(serviceSelected.minTime);
        if (independent == 1) {
        if (serviceSelected.professionalId == 0) {
            let hoursTem: HourModel[] = [];
            let hoursTempIds: number[] = [];
                if (quantityHourService == 1) {
                   hoursTem.push({hourId: hour.hour_id});
                    hoursTempIds.push(hour.hour_id);
                    const addScheduleNow = await addSchedule(selectedDateFormat, serviceSelected, id, services, hoursTem, id);
                    const hoursChange = await assignedHour(hours, hoursTempIds);
                    dispatch(setAssignedSchedule({hours: hoursChange, services: addScheduleNow }))
                } else {
                    const hoursCandidates = await getHours(quantityHourService, hour);
                    if (hoursCandidates.length > 0) {
                        // const appointmentHourIds = _.map(hours, 'hour_id');
                        const appointmentHourIds = hoursCandidates.map((eh:any) => eh.hour_id);
                        const hoursTempIds = hoursCandidates.map((eh:any) => {
                            return {hourId: eh.hour_id}
                        });
                        const available = await availableHours(appointmentHourIds);
                        if (available) {
                            const addScheduleNow = await addSchedule(selectedDateFormat, serviceSelected, id, services, hoursTempIds, id);
                            const hoursChange = await assignedHour(hours, appointmentHourIds);
                            dispatch(setAssignedSchedule({hours: hoursChange, services: addScheduleNow }))
                            // await addAppointmentHourMore(currentDateSelectedRef.current,serviceSelectedRef.current, providerService.id, appointmentHourIds);
                        }
                    } else {
                        alert(' Se requiere ' + quantityHourService + ' horas en secuencia disponible');
                    }

                }
            } else {

            }
        } else {
            const hoursCandidates = await getHours(quantityHourService, hour);
            const appointmentHourIds = hoursCandidates.map((h:any) => h.hour_id);
            const hoursTem : HourModel[] = hoursCandidates.map((hh:any) => {
                return {hourId: hh.hour_id};
            });
            const {id,independent} = provider as any
            const params = {
                id: id,
                schedule_date: selectedDateFormat,
                independent: independent,
                type_id: serviceSelected.type,
                schedule_user_id: appointmentHourIds.toString(),
            };
            const professional = await getProfessionals(params);
            if (professional.length > 0) {
                setShow(true);
                setProfessionals(professional);
                if ( quantityHourService == 1 ) {
                    // hoursTem.push({hourId: hour.hour_id});
                    // hoursTempIds.push(hour.hour_id);
                    setData({...data,  date: selectedDateFormat, service: serviceSelected, hours: hoursTem })
                } else {
                    setData({...data,  date: selectedDateFormat, service: serviceSelected, hours: hoursTem })
                }

            } else {
                alert(" Profesionales no disponibles")
            }
        }
    } else {
       await onDelete( hour);
    }
}
const getProfessionals = async (params: any) => {
    const {response, success, errors} = await getHttp(buildUrlClient('scheduleAvailableProfessional'), params);
    if (success) {
        return response;
    }
    return [];
}
    const availableHours = (hoursCandidates: HourDayModel[]) => {
        for (let key in services) {
            // @ts-ignore
            const itemServices = services[key];
            for (let ky in itemServices) {
                const itemService = itemServices[ky];
                const itemHours = itemService.hours;
                if (itemHours?.length > 0) {
                    for (let k in itemHours) {
                        if (hoursCandidates.includes(itemHours[k])) {
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    };
const getHours = async (horas: number, hour: any) => {
        let hourFinal = [];
        let hourCandidates = [];
        let insert = false;
        for (let i = 0; i < hours.length; i++) {
            if (hours[i].hour_id == hour.hour_id) {
                insert = true;
            }
            if (insert) {
                hourCandidates.push(hours[i]);
            }
        }
        if (horas > hourCandidates.length) {
            return [];
        } else {
            for (let j = 0; j < hourCandidates.length; j++) {
                if (j < horas) {
                    hourFinal.push(hourCandidates[j]);
                }
            }
        }

        let exists = hourFinal.filter((eleh: any) => eleh.enabled == 0);
        if (exists.length > 0) {
            return [];
        } else {
            return hourFinal;
        }
}
const handleSelected = () => {

}
const onSelected =  async (item: any) => {
  setShow(false);
    const {id,independent} = provider as any
    const addScheduleNow = await addSchedule(data.date!, data.service, id, services, data.hours, item.id);
    const appointmentHourIds = data.hours.map((eh:any) => eh.hourId);
    const hoursChange = await assignedHour( hours, appointmentHourIds);
    dispatch(setAssignedSchedule({hours: hoursChange, services: addScheduleNow }))

}
    const onDelete = async (hour: any) => {
      if (hour.assigned == 1) {
          const currentHours =await getRemoveHours(hour, services);
          const hoursIds  = currentHours.map((item: any) => {
              return item.hourId;
          });
          const updateService = await getUpdateService(hour, services);
          let hoursTemp = [];
          if (hoursIds.length == 0) {
               hoursTemp = await updateHour(hours, hour);
          } else {
              hoursTemp = await unAssignedHour(hours, hoursIds);
          }

          dispatch(setAssignedSchedule({hours: hoursTemp, services: updateService }))
      }
    }
    const updateHour = async (hoursParam: any, hourSelected: any) => {
        let list = [];
        for (const hoursKey in hoursParam) {
            let item =  hoursParam[hoursKey];
            if (hourSelected.hour_id == item.hour_id) {
                list.push({...item, assigned: 0, professionalId: 0});
            } else {
                list.push({...item});
            }
        }
        return list;
    }
    const unAssignedHour = async (hoursParam: any, hoursTempIds: number[]) => {
        let list = [];
        for (const hoursKey in hoursParam) {
            let item =  hoursParam[hoursKey];
            if (hoursTempIds.includes(item.hour_id)) {
                list.push({...item, assigned: 0, professionalId: 0});
            } else {
                list.push({...item});
            }
        }
        return list;
    }
    const getUpdateService = async (hour: any, serviceParams: any) => {
        let typeNew = [];
            for (const key in serviceParams) {
                let type = serviceParams[key];
                let {service} = serviceParams[key];
                let servicesNew = [];
                for (const up in service) {
                    const item = service[up];
                    if (item.hours.some((h: any) => h.hourId === hour.hour_id)) {
                        servicesNew.push({
                            ...item,
                            hours: [],
                            date: '',
                            userId: '',
                            serviceId: '',
                            professionalId: '',
                            salonId: '',

                        })
                    } else {
                        servicesNew.push(item);
                    }
                }
                type = {...type, service: servicesNew}
                typeNew.push(type);
            }
        return typeNew;
        }
    const getRemoveHours = async (hour: any, servicesParams: any) => {
        for (let key in servicesParams) {
            const serviceAux = servicesParams[key];
            for (let index in serviceAux.service) {
                const item = serviceAux.service[index];
                if(item.hours.some((h: any) => h.hourId === hour.hour_id)) {
                    return item.hours
                }
            }
        }
        return [];
    }
    const onSelectedService = (service: any, type: any) => {
        let newTypes: any[] = [];
        for (const k in services) {
            // @ts-ignore
            let typeItem = services[k];
                let serviceItems = typeItem.service;
                let newServices = [];
                for (const j in serviceItems) {
                    let serviceItem = serviceItems[j];
                    if ( serviceItem.itemId == service.itemId) {
                        newServices.push({...serviceItem, selected: true});
                    } else {
                        newServices.push({...serviceItem, selected: false});
                    }
                }
                typeItem = {...typeItem, service: newServices}
            newTypes.push(typeItem);
        }
        dispatch(setServicesSchedule({services: newTypes}));
    }

    return <div>
            <div className="custom-scrollbar h-auto os-host os-theme-dark os-host-overflow os-host-overflow-y">
                {services?.map((type: any, index: number) => (
                    <div key={index}>
                    {type?.service.map((service: any, key: number) => (
                <div  key={key+index} className="d-flex justify-content-between position-relative">
                    <div className="d-sm-flex">
                        <div className="ms-0 ms-sm-3 mt-2 mt-sm-0">
                            <h6 className="mb-0">{service.name}</h6>
                            <p className="mb-0">{service.description}</p>
                            <span className="small">Tiempo estimado : {service.minTime} a {service.maxTime} min.</span>
                        </div>
                    </div>
                    <h6 className="mb-0 fw-light">BS. {service.cost}</h6>
                    <input className="form-check-input" type="checkbox" value={service.selected}  checked={service.selected} onClick={() =>onSelectedService(service, type)}/>
                       </div>
                        ))}
                    </div>
                    )
                  )
                }
                <hr/>
            </div>
        <div className={'row'}>
            <Calendar
                tileDisabled={(date) => dateDisable(date)}
                onChange={(value) => onSelectedDay(value)}
                value={selectedDate} />
        </div>
        <div className={'row'}>
            <div className="col-12">
                <h5 className="mb-3">Horas disponibles</h5>
                <ul className="list-inline hstack m-2 flex-wrap">
                {hours.map((hour: any, i: number) => (
                    <>
                    {hour.enabled === 1  && <div key={i} className={'hour'}>
                    <li className="list-inline-item">
                            <button  className= {`btn btn-md ${hour.assigned == 0 ? "btn-success" : "react-calendar__tile--now"} `}
                                     onClick={() => onClickHour(hour)}>
                                {hour.time_ini} - {hour.time_end} {' '}
                                {hour.assigned == 1 && <i className="bi bi-trash span-action" ></i>}
                            </button>
                        </li>
                    </div>}
                    </>
                ))}
                </ul>
            </div>
        </div>
        <Modal  show={show}
                backdrop="static"
                keyboard={false}
                size={'lg'}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setShow(false)}
                dialogClassName="modal-100W"
                animation={true}
                contentClassName={'modal-custom'}
        >
            <Modal.Header closeButton>
                <Modal.Title>Seleccione un profesional</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container position-relative">
                    <div className="row g-4 g-lg-5">
                        {professionals.map((item: any, index: number) => (
                                <div className="col-sm-6 col-xl-3" key={index} onClick={() => onSelected(item)}>
                                    <div className="bg-body shadow rounded-3 text-center position-relative">
                                        <img src={item.perfilLink} className="h-100px mb-3" alt="" />
                                        <h6 className="mb-2"><a href="#" className="stretched-link">{item.name}</a></h6>
                                        <p className="text-truncate-2 mb-2">{item.last_name} </p>
                                    </div>
                            </div>
                        ) )}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => handleSelected()}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    </div>
}
