import {getServiceHotel} from "../store/thunks/serviceHotelThunk";
import {useAppDispatch, useAppSelector} from "../hooks/hook";
import {useEffect, useId} from "react";
import {useNavigate} from "react-router-dom";
import {getProviders} from "../store/thunks/providerThunk";
import {RootState} from "../store/store";
import InfiniteScroll from "react-infinite-scroll-component";

export const ServiceHotel = () => {
  const dispatch = useAppDispatch()
  const userId = useId()
  const navigate = useNavigate();
  const {serviceHotel, next_page} = useAppSelector((state: RootState) => state.serviceHotel);

  useEffect(() => {
    (async () => await loadData())();
  }, []);
  const loadData = () => {
    dispatch(getServiceHotel(1, '', 0, 0))
  }
  const onCard = (item: any) => {
console.log(' item ', item);
    return  navigate(`./../service/${item.id}`);
  }
  const onNextPage = () => {

  }

  return <div>
    <InfiniteScroll
        dataLength={serviceHotel.length} //This is important field to render the next data
        next={() => onNextPage()}
        hasMore={next_page != null ? true : false}
        loader={<h4 style={{ textAlign: 'center' }}>Cargando...</h4>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            { serviceHotel.length > 0 ? <b>Se listo todos los servicios</b> : <b>No hay servicios</b>}
          </p>
        }
        // below props only if you need pull down functionality
        refreshFunction={() => console.log("local")}
        pullDownToRefresh
        pullDownToRefreshThreshold={50}
        pullDownToRefreshContent={
          <h3 style={{ textAlign: 'center' }}></h3>
        }
        releaseToRefreshContent={
          <h3 style={{ textAlign: 'center' }}></h3>
        }
    >
      <section>
        <div className="container" >
          <div className="row mb-4">
            <div className="col-12 mx-auto">
              <h2 className="mb-0">Servicios</h2>
            </div>
          </div>
          <div className="">
            <div className="tab-pane " id="course-pills-tabs-5" role="tabpanel"
                 aria-labelledby="course-pills-tab-5">
              <div className="row g-4">
                {serviceHotel?.map((item, index) => (
                    <div className="col-sm-6 col-lg-4 col-xl-3" key={index} onClick={() => onCard(item)}>
                      <div className="card bg-transparent">
                        <div className="overflow-hidden rounded-3">
                          <img src={item.original_image} className="card-img" alt="course image" />
                            <div className=" bg-dark"></div>
                            {/*<div className="card-img-overlay d-flex align-items-start p-3">*/}

                            {/*  <a href="#" className="badge text-bg-danger">Student life</a>*/}
                            {/*</div>*/}
                        </div>
                        <div className="card-body">
                          <h5 className="card-title">{item.name}</h5>
                          <p className="text-truncate-2">{item.description}</p>
                          <div className="d-flex justify-content-between">
                            <h6 className="mb-0">Precio {item.price}</h6>
                            <div
                                className="icon-md-app bg-orange bg-opacity-15 text-orange rounded-circle">
                              <i className="fas fa-arrow-right"></i></div>
                            {/*<span className="small">30M Ago</span>*/}
                          </div>
                        </div>
                      </div>
                    </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </InfiniteScroll>
  </div>
}
