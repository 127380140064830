// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth, getRedirectResult, FacebookAuthProvider } from "firebase/auth";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDKeVd8cjB9Lhwh6UKLWLlxiooE78Gcsl4",
    authDomain: "bela2go-d330d.firebaseapp.com",
    databaseURL: "https://bela2go-d330d.firebaseio.com",
    projectId: "bela2go-d330d",
    storageBucket: "bela2go-d330d.appspot.com",
    messagingSenderId: "996576996764",
    appId: "1:996576996764:web:a09303e6e220e050a2ec59",
    measurementId: "G-GMNWE1JM1E"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig, 'Bela2go');
const analytics = getAnalytics(app);
const auth = getAuth(app);

const provider = new FacebookAuthProvider();

export {auth, provider}
