import { createSlice } from '@reduxjs/toolkit'

export interface ServiceHotelState {
    page: number,
    serviceHotel: any[],
    next_page: null,
    isLoading: boolean
}

const initialState: ServiceHotelState = {
    page: 1,
    serviceHotel: [],
    next_page: null,
    isLoading: false,
}

export const serviceHotelSlice = createSlice({
    name: 'serviceHotel',
    initialState,
    reducers: {
        isLoading: (state, action) => {
            state.isLoading = action.payload.loading;
        },
        setServiceHotel: (state,action) => {
            state.isLoading = false;
            state.serviceHotel =  action.payload.page == 1? action.payload.serviceHotel : [...state.serviceHotel, ...action.payload.serviceHotel];
            state.page =  action.payload.page;
            state.next_page = action.payload.next_page;
        },
    },
})

// Action creators are generated for each case reducer function
export const { isLoading, setServiceHotel } = serviceHotelSlice.actions
