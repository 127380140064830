import {AxiosResponse} from 'axios';
import {apiClient} from "./ApiClient";
export const getHttp = async (url: string, params: any = []) => {
  try {
    const res = await apiClient.get<AxiosResponse<any>>(url, {
      params,
    });
    const {data} = res as any;
    return {response: data?.data, success: true, message: data?.message};
  } catch (e: any) {
    console.log(' e ', e);
    return {response: [], success: false, message: ' Error en el servidor', errors: e?.response?.data};
  }
};
export const getHttpList = async (url: string, params: any = []) => {
  try {
    const res = await apiClient.get<AxiosResponse<any>>(url, {
      params,
    });
    const {data} = res;
    return {response: data?.data, success: true};
  } catch (e: any) {
    return {response: [], success: false, message: ' Error en el servidor', errors: e?.response?.data};
  }
};
export const editHttp = async (url: string, id: number, params: any = []) => {
  try {
    const res = await apiClient.get<AxiosResponse<any>>(`${url}/${id}/edit`, {
      params,
    });
    const {data} = res as any;
    return {response: data?.data, success: true, message: data?.message};
  } catch (e: any) {
    return {response: [], success: false, message: ' Error en el servidor', errors: e?.response?.data};
  }
};

export const postHttp = async (url: string, body: any, multiPart = false) => {
  try {
    if (multiPart) {
      apiClient.defaults.headers['Content-Type'] = 'multipart/form-data';
    } else {
      apiClient.defaults.headers['Content-Type'] = 'text/json';
    }
    const res = await apiClient.post<AxiosResponse<any>>(url, body);
    const {data} = res as any;
    return {response: data?.data, success: true, message: data?.message};
  } catch (e: any) {
    console.log(e?.response?.data?.message)
    const messageShow = e?.response?.data?.message ? e?.response?.data?.message :  "Error en el servidor";
    return {response: [], success: false, message: messageShow, errors: e?.response?.data};
  }
};
export const putHttp = async (url: string, id: number, body: any, multiPart: boolean = false) => {
  try {
    if (multiPart) {
      apiClient.defaults.headers['Content-Type'] = 'multipart/form-data';
    } else {
      apiClient.defaults.headers['Content-Type'] = 'text/json';
    }
    const res = await apiClient.post<AxiosResponse<any>>(`${url}/${id}`, body);
    const {data} = res as any;
    return {response: data?.data, success: true, message: data?.message};
  } catch (e: any) {
    const messageError = e?.response.data;
    return {response: [], success: false, message:  messageError?.message ?  messageError?.message : ' Error en el servidor', errors: e?.response?.data};
  }
}
export const deleteHttp = async (url: string, id: number) => {
  try {
    const res = await apiClient.delete<AxiosResponse<any>>(`${url}/${id}`);
    return {response: [], success: true, message: 'La operación fue exitoso'};
  } catch (e: any) {
    return {response: [], success: false, message: 'Error al eliminar', errors: e?.response?.data}
  }
};
export const deleteByIdsHttp = async (url: string, id: string) => {
  try {
    const res = await apiClient.delete<AxiosResponse<any>>(`${url}/${id}`);
    return {response: [], success: true, message: 'La operación fue exitoso'};
  } catch (e: any) {
    return {response: [], success: false, message: 'Error al eliminar', errors: e?.response?.data}
  }
};
export const putHttpOnly = async (url: string, id: any, body: any = []) => {
  try {
    // httpApi.defaults.headers['Content-Type'] = 'text/json';
    const res = await apiClient.put<AxiosResponse<any>>(
      `${url}/${id}`,
      body,
    );
    const {data} = res as any;
    return {response: data?.data, success: true, message: data?.message};
  } catch (e: any) {
    const messageError = e?.response.data;
    return {
      response: [],
      success: false,
      message: messageError?.message
        ? messageError?.message
        : ' Error en el servidor',
      errors: messageError,
    };
  }
};
