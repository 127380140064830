import {Header} from "../layout/header";
import {ServiceHotel} from "../compoments/ServiceHotel";
import {Footer} from "../layout/footer";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getHttp} from "../api/HttpClient";
import {buildUrlClient} from "../utils/BuildUrlClient";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {Counter} from "../models/Counter";
import {
  initData,
  initPayment,
  initPaymentService, setCounterAddrress,
  setCounters,
  setProvider, setServices,
  setShowModalAddrress,
  setStepper
} from "../store/slices/appointmentSlice";
import {useAppDispatch, useAppSelector} from "../hooks/hook";
import {RootState} from "../store/store";
import {STEPPERS} from "../utils/Constants";
import {setShowModal} from "../store/slices/authSlice";
import {Modal} from "react-bootstrap";
import {Appointment} from "../compoments/Appointment";
import {getCheckStepperCondition} from "../core/calendar/getCheckStepperCondition";
import {getNextStepper} from "../core/calendar/getNextStepper";
import {onCalendar, onConfirm} from "../store/thunks/appointmentThunk";

export  const ServicePage = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const [serviceProfessionals, setServiceProfessionals] = useState([]);
  const [serviceHotel, setServiceHotel] = useState(null) as any;
  const [show, setShow] = useState(false);
  const [userProvider, setUserProvider] = useState({}) as any;
  const {status, showModal, session} = useAppSelector((state: RootState) => state.auth);
  const {stepper, provider, payment, showModalAddrress, counterAddrress} = useAppSelector((state: RootState) => state.appointment) as any;

  const {confirmSchedule, qr} = useAppSelector((state: RootState) => state.confirm)
  console.log(' id ', id);
  useEffect(() => {
    (async () => await loadDataCheck())();
  }, [status]);
  useEffect(() => {
    (async () => await loadData())();
  }, []);
  const loadDataCheck = async () => {
    if (status == 'authenticated') {
      await  checkAddress()
    }
  }
  const checkAddress =async () => {
    const {response, success} = await getHttp(buildUrlClient('checkAddress'));
    if (success) {
      dispatch(setCounterAddrress({counterAddrress: response.count}));
    }
  }
  const loadData = async () => {
    dispatch(setStepper({stepper: STEPPERS.CALENDAR}))
    // dispatch(setProvider({provider: {id: id, independent: independent}}));
    dispatch(initPaymentService());
    const {response,success} = await getHttp(buildUrlClient(`serviceHotelById/${id}`));
    if (success) {
      setServiceHotel({...response, count: 1});
      const newList = response.professionals.map((item: any) => {
        return {
          ...item,
          selected: false,
        };
      })
      setServiceProfessionals(newList);
    }
  }
  const onSelected = (item: any) => {
    console.log(' item ', item);
    const list = [];
    for (let key in serviceProfessionals) {
      const elem = serviceProfessionals[key] as any;
      if (elem?.id == item.id) {
      const  newElem = {
          ...elem,
          selected: !elem.selected
        }
        list.push(newElem);
      } else {
        list.push({...elem, selected: false});
      }
    }
    // @ts-ignore
    setServiceProfessionals(list);
  }
  const onAdd = (service: any) => {
    console.log(' service ', service);
    if (serviceHotel?.count < 20) {
      setServiceHotel({...serviceHotel, count: serviceHotel.count + 1});
    }
  }
  const getCounter = (service: any) => {
    return serviceHotel?.count | 0;
  }
  const onMinus = (service: any) => {
    if (serviceHotel.count > 1) {
      setServiceHotel({...serviceHotel, count: serviceHotel.count - 1});
    }
  }
  const onShowModal = async () => {
    const {counters, appointments} = payment;
    console.log(' service modal ')
    if (status == 'authenticated') {
      if (serviceHotel?.count > 0) {
        const pro: any = serviceProfessionals.find((item: any) => item.selected == true);
        if (pro) {
          console.log(' prod', pro);
          await updateCounterService(serviceHotel, pro);
          dispatch(setProvider({provider: {id: pro.id, independent: 1}}));
          dispatch(initData({id: pro.id, independent: 1}));
          dispatch(setStepper({stepper: STEPPERS.CALENDAR}));
          await loadServices(pro.id, '', pro.service_id);
          setShow(true);
        } else {
          alert(" Seleccione el profesional ");
        }

      } else {
        alert(" Seleccione por lo menos un servivio ");
      }
    } else {
      dispatch(setShowModal({showModal: true}));
      // alert('Inicie Sesión')
    }
  }
  const updateCounterService = async (service:any, professional: any) => {
    let {counters} = payment;
    const yep: Counter = {serviceId: professional.service_id, typeId: 0,  count: service.count};
    console.log('counters ', counters);
    console.log('yep ', yep);
    dispatch(setCounters({counters: [...counters, yep]}));
  }
  const loadServices = async (userId: string | undefined, promotionId: string | undefined, serviceId: string | undefined) => {
    const promIdTemp = Number(promotionId) > 0 ? promotionId : 0;
    const params = {
      page: 1,
      userId: userId,
      typeId: 0,
      promoId: promIdTemp,
      serviceId: serviceId
    };
    console.log(' params ', params);
    const {response, success} = await getHttp(buildUrlClient('servicesFilter'), params)
    if (success) {
      dispatch(setServices({services: response}));
    } else {
      dispatch(setServices({services: []}));
    }
  }
  const onClose = () => {
    const {appointments} = payment;
    if (confirmSchedule) {
      setShow(false);
      return  navigate('/');
    } else {
      const ids = appointments?.map((item: any) => item.appointment_id).toString();
      if (ids != '' ) {
        // dispatch(deleteAppointmentByIds(ids));
      }
      setShow(false);
    }
  }
  const onNext =async () => {
    if (counterAddrress > 0) {
      const stepperCondition = await getCheckStepperCondition(stepper,payment);
      if (stepperCondition) {
        const next = await getNextStepper(stepper);
        switch (stepper) {
          case STEPPERS.CALENDAR:
            dispatch(onCalendar(payment,next));
            return;
          case STEPPERS.PAYMENT:
            dispatch(onConfirm(payment,next));
            return;
        }
      } else {
        alert('Asigne las horas a los servicios');
      }
    } else {
      dispatch(setShowModalAddrress({showModalAddrress: true}))
      alert('Registre su direccion')
    }
  }
  return <>
    <Header />
    <div className={'container'}>
      <div className="page-content">
        <div className="page-content-wrapper border">
      <div className="card ">
        <div className="d-sm-flex justify-content-between align-items-center">
          <h4 >Agendar el servicio </h4>
          <div className="mt-2 mt-sm-0">
            <button className="btn btn-success mb-0" onClick={() => onShowModal()} >Siguiente</button>
          </div>
        </div>
        <div className="card-header border-bottom">

          <div className="card">
            <div className="row g-0">
              <div className="col-md-3">
                <img src={serviceHotel?.resize_image} className="rounded-2" alt="Card image" />
                {/*<img src="./../assets/images/01.jpg" className="rounded-2" alt="Card image" />*/}
              </div>
              <div className="col-md-9">
                <div className="card-body">
                  <h3 className="card-title">{serviceHotel?.name}
                  </h3>
                  <p className="text-truncate-2 d-none d-lg-block">{serviceHotel?.description}</p>
                  {/*<ul className="list-inline mb-2">*/}
                  {/*  <li className="list-inline-item h6 fw-light mb-1 mb-sm-0"><i*/}
                  {/*      className="far fa-clock text-danger me-2"></i>6h 56m*/}
                  {/*  </li>*/}
                  {/*  <li className="list-inline-item h6 fw-light mb-1 mb-sm-0"><i*/}
                  {/*      className="fas fa-table text-orange me-2"></i>82 lectures*/}
                  {/*  </li>*/}
                  {/*  <li className="list-inline-item h6 fw-light"><i className="fas fa-signal text-success me-2"></i>Beginner*/}
                  {/*  </li>*/}
                  {/*</ul>*/}
                  {/*<a href="#" className="btn btn-primary-soft btn-sm mb-0">Resume course</a>*/}
                  <div className="mb-0 text-nowrap">
                    <button className="btn btn-danger-soft  btn-round me-1 mb-1 mb-md-0 " onClick={() => onMinus(serviceHotel)}>
                      <FontAwesomeIcon icon={faMinus} />
                    </button>
                    <button className="btn btn-secondary-soft  btn-round me-1 mb-1 mb-md-0" disabled={true}>
                      <p>{getCounter(serviceHotel)}</p>
                    </button>
                    <button className="btn btn-success-soft btn-round me-1 mb-1 mb-md-0" onClick={() => onAdd(serviceHotel)}>
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          <h5>Seleccione un profesional</h5>
          <div className="row g-4 g-lg-5">
            {serviceProfessionals.map((item: any, index: number) => (
                <div className="col-sm-6 col-xl-3" key={index} onClick={() => onSelected(item)}>
                  <div className="bg-body shadow rounded-3 text-center p-2 position-relative">
                    <img src={item.perfilLink} className="h-200px  w-100" alt=""/>
                    <h5 className="mb-2">{item.name}</h5>
                    <p className="text-truncate-2 mb-2">Man children rejoiced. Yet uncommonly his ten. </p>
                    <span className="mb-0">5 Course</span>
                    <div className={'row'}>
                      <div>
                        <span className="badge bg-success bg-opacity-10 text-success">{item.selected ? 'Seleccionado': ''}  </span>
                      </div>
                    </div>

                  </div>
                </div>
            ))}
          </div>
        </div>

        <div className="d-sm-flex justify-content-end align-items-center">
          <div className="mt-2 mt-sm-0">
            <button className="btn btn-success mb-0" onClick={() => onShowModal()} >Siguiente</button>
          </div>
        </div>
      </div>
        </div>
      </div>

      {show && <Modal
          backdrop="static"
          keyboard={false}
          size={'xl'}
          show={show}
          onHide={() => onClose()}
          dialogClassName="modal-100W"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Creando la cita.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Appointment />
        </Modal.Body>
        <Modal.Footer>
          {!confirmSchedule &&
              <div className="col-12 d-flex justify-content-end">
                <button className="btn btn-success mb-0" type="button" onClick={() => onNext()}>Siguente</button>
              </div>}
        </Modal.Footer>
      </Modal>
      }
    </div>
    <Footer />
  </>
}
